exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-src-articles-home-away-advantage-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/Users/baptiste/Documents/baptiste/ruck-de-data/blog/src/articles/home_away_advantage.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-src-articles-home-away-advantage-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-src-articles-kicks-success-predictor-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/Users/baptiste/Documents/baptiste/ruck-de-data/blog/src/articles/kicks_success_predictor.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-src-articles-kicks-success-predictor-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-src-articles-maximize-kick-angle-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/Users/baptiste/Documents/baptiste/ruck-de-data/blog/src/articles/maximize_kick_angle.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-src-articles-maximize-kick-angle-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-src-articles-rugby-games-prediction-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/Users/baptiste/Documents/baptiste/ruck-de-data/blog/src/articles/rugby_games_prediction.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-src-articles-rugby-games-prediction-mdx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

